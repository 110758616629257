<template>
  <div v-if="!events" class="mt-3">
    <span class="fs-4">Aktuell sind keine Termine vorhanden.</span>
  </div>
  <div else class="row mt-3" v-for="(event) in events" :key="event.eventId">
    <div class="col-12">
      <div class="card text-start">
        <div class="card-header">
          <h6>
            {{ event.name }}, am {{ getDateTime(event.start, 'dd., DD.MM.YYYY') }},
            {{ getDateTime(event.start, 'hh:mm') }} Uhr
          </h6>
        </div>
        <div class="card-body">
          <EventUserDetailComp @update-seller="update" :is-seller="isSeller" :persons="persons" :event="event" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref } from 'vue'
import { ApiResponse } from '@/services/api/api'
import '@/validations'
import moment from 'moment'
import EventUserDetailComp from '@/components/shop/event/user/EventUserDetailComp.vue'
import { getAllEventsForUserApi } from '@/services/api/event/api'
import { Event } from '@/store/modules/event/interfaces'
import { AUTH_STORE, SHOP_STORE } from '@/store/constants'
import { store } from '@/store'
import { Person } from '@/services/api/person/interfaces'

export default defineComponent({
  name: 'EventUserListComp',
  components: { EventUserDetailComp },
  emits: ['updateEvents'],
  props: {
    persons: {
      type: Object as PropType<Person[]>,
      required: true
    },
    dateFrom: {
      type: Date,
      required: false
    },
    dateTo: {
      type: Date,
      required: false
    },
    isSeller: {
      type: Boolean,
      required: false
    },
    sort: {
      type: String,
      required: false
    }
  },
  setup (props, { emit }) {
    const shop = computed(() => store.getters[SHOP_STORE.GETTERS.GET_SHOP_DATA])
    let lastEventId = 'old'
    const events = ref<Event[]>()

    function isNewEvent (eventId: string, index: number): boolean {
      const lastId: string = lastEventId
      lastEventId = eventId
      return lastId !== eventId || index === 0
    }

    function getDateTime (date: Date, format: string) {
      return moment(date).format(format)
    }

    async function getEvents () {
      try {
        const apiResponse: ApiResponse = await getAllEventsForUserApi(
          props.dateFrom, props.dateTo, props.isSeller, shop.value.id, props.sort
        )
        if (apiResponse.data && apiResponse.data.events) {
          events.value = apiResponse.data.events
        }
      } catch (e) {
        events.value = []
      }
    }
    getEvents()

    function update () {
      emit('updateEvents')
    }

    return {
      events,
      isNewEvent,
      getDateTime,
      update
    }
  }
})
</script>

<style scoped>

</style>
