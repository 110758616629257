<template>
  <div class="event-seller-user-list-view container-lg" v-if="!isLoading">
    <h1>Übersicht der Verkäufe</h1>
    <div class="card">
      <div class="card-header">
        <h4>{{ event.name }} am {{ getDateTime(event.eventStart, 'dd., DD.MM.YYYY') }}</h4>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-12 col-md-6">
            <p class="fs-4 mb-0"><strong>{{ person ? person.lastname + ', ' + person.firstname : '' }}</strong></p>
            <p class="mb-0"><small class="text-secondary">Verkäufername</small></p>
          </div>
          <div class="col-12 col-md-6">
            <p class="fs-4 mb-0"><strong>{{ currentUser.username }}</strong></p>
            <p class="mb-0"><small class="text-secondary">User</small></p>
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-12 col-md-6">
            <p class="fs-4 mb-0"><strong>{{ countBookings }}</strong></p>
            <p class="mb-0"><small class="text-secondary">Verkaufte Artikel</small></p>
          </div>
          <div class="col-12 col-md-6">
            <p class="fs-4 mb-0"><strong>{{ getCurrency(sumBookings) }}</strong></p>
            <p class="mb-0"><small class="text-secondary">Verkaufserlös</small></p>
          </div>
        </div>
      </div>
    </div>
    <div class="card mt-3">
      <div class="card-body">
        <table class="table table-striped">
          <thead class="table-dark">
          <tr>
            <th scope="col">#</th>
            <th scope="col">Verkaufspreis</th>
            <th scope="col">Provision</th>
            <th scope="col">Auszahlung</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(booking, index) in bookings" :key="booking.bc">
            <th scope="row">{{ index + 1 }}</th>
            <td>{{ getCurrency(booking.bc) }}</td>
            <td>{{ getCurrency(booking.bp) }}</td>
            <td>{{ getCurrency(booking.bs) }}</td>
          </tr>
          </tbody>
          <tfoot>
          <tr class="table-dark">
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>{{ getCurrency(sumBookings) }}</td>
          </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref } from 'vue'
import { Shop } from '@/store/modules/shop/interfaces'
import { useStore } from '@/use/useStore'
import { useRoute } from 'vue-router'
import { AUTH_STORE } from '@/store/constants'
import { getAllForSellerApi } from '@/services/api/booking/api'

import moment from 'moment'
import { Event } from '@/store/modules/event/interfaces'
import { Person } from '@/services/api/person/interfaces'
import { getPersonBySellerIdApi } from '@/services/api/person/api'
import { getEventBySellerIdApi } from '@/services/api/event/api'

export default defineComponent({
  name: 'SellerUserBookings',
  props: {
    shop: {
      type: Object as PropType<Shop>
    }
  },
  setup () {
    const store = useStore()
    const route = useRoute()
    const currentUser = computed(() => store.getters[AUTH_STORE.GETTERS.GET_USER_DATA])
    const isLoading = ref(false)
    const bookings = ref()
    const sumBookings = ref(0)
    const countBookings = ref(0)
    const event = ref<Event>()
    const person = ref<Person>()
    const sellerId: string = route.params.sellerId as string

    isLoading.value = true

    async function getPerson (sellerId: string) {
      try {
        person.value = await getPersonBySellerIdApi(sellerId)
      } catch (e) {
        return e
      }
    }

    getPerson(sellerId)

    async function getBookings (sellerId: string) {
      try {
        bookings.value = await getAllForSellerApi(sellerId)
        if (bookings.value) {
          for (let c = 0; c < bookings.value.length; c++) {
            const booking = bookings.value[c]
            sumBookings.value = sumBookings.value + booking.bs
          }
          countBookings.value = bookings.value.length
        }
      } catch (e) {
        return e
      }
    }

    getBookings(sellerId)

    async function getEvent (sellerId: string) {
      try {
        event.value = await getEventBySellerIdApi(sellerId)
        isLoading.value = false
      } catch (e) {
        isLoading.value = false
        return e
      }
    }

    getEvent(sellerId)

    function getCurrency (amount: number): string {
      const formatter = new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR'
      })

      return formatter.format(amount)
    }

    function getDateTime (date: Date, format: string) {
      return moment(date).format(format)
    }

    return {
      currentUser,
      bookings,
      isLoading,
      sumBookings,
      countBookings,
      event,
      person,
      getCurrency,
      getDateTime
    }
  }
})
</script>

<style scoped>

</style>
