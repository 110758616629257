
import { computed, defineComponent, PropType, ref } from 'vue'
import { Shop } from '@/store/modules/shop/interfaces'
import { useStore } from '@/use/useStore'
import { useRouter } from 'vue-router'
import { AUTH_STORE } from '@/store/constants'
import EventUserListComp from '@/components/shop/event/user/EventUserListComp.vue'
import { getAllPersonsForUserApi, getPersonForUser } from '@/services/api/person/api'
import { getAddressByRefRole } from '@/services/api/address/api'
import { Person } from '@/services/api/person/interfaces'
import moment from 'moment'

export default defineComponent({
  name: 'SellerUserEvents',
  components: { EventUserListComp },
  props: {
    shop: {
      type: Object as PropType<Shop>
    }
  },
  setup () {
    const store = useStore()
    const router = useRouter()
    const currentUser = computed(() => store.getters[AUTH_STORE.GETTERS.GET_USER_DATA])
    const isLoading = ref(false)
    const person = ref()
    const persons = ref<Person[]>()
    const address = ref()

    async function getPerson () {
      try {
        person.value = await getPersonForUser()
        if (person.value) {
          address.value = await getAddressByRefRole(person.value.id, 'person', 'main')
        }
      } catch (e) {
        return e
      }
    }

    getPerson()

    async function getAllPersons () {
      try {
        persons.value = await getAllPersonsForUserApi()
        isLoading.value = false
      } catch (e) {
        isLoading.value = false
        return e
      }
    }

    getAllPersons()

    async function reload () {
      router.go(0)
    }

    function getDateTimeNewEvents () {
      return moment(new Date().setUTCHours(23, 59, 59, 9999)).add(12, 'd').toDate()
    }

    return {
      currentUser,
      person,
      persons,
      address,
      isLoading,
      getPerson,
      reload,
      getDateTimeNewEvents
    }
  }
})
