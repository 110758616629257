
import { defineComponent, PropType, ref } from 'vue'
import moment from 'moment'
import { Seller } from '@/store/modules/seller/interfaces'
import { createSellerApi, deleteSellerApi, getSellersDetailsByUserIdAndRefIdApi } from '@/services/api/seller/api'
import { Event } from '@/store/modules/event/interfaces'
import { Person } from '@/services/api/person/interfaces'

export default defineComponent({
  name: 'EventUserDetailComp',
  emits: ['updateSeller'],
  props: {
    event: {
      type: Object as PropType<Event>,
      required: true
    },
    persons: {
      type: Object as PropType<Person[]>,
      required: true
    },
    isSeller: {
      type: Boolean,
      required: false
    }
  },
  setup (props, { emit }) {
    const isLoading = ref(false)

    function getDateTime (date: Date, format: string) {
      return moment(date).format(format)
    }

    const sellersDetails = ref()

    async function getSellersDetails () {
      const refId = props.event.id
      const refType = 'event'
      try {
        sellersDetails.value = await getSellersDetailsByUserIdAndRefIdApi(refId, refType)
      } catch (e) {
        return e
      }
    }

    getSellersDetails()

    function getSellerForPerson (person: Person): any | void {
      const persId: string = person.id || ''
      return sellersDetails.value ? sellersDetails.value.find((o: any) => o.persId === persId) : undefined
    }

    function showSellerCheckIn (person: Person) {
      const currentDate = moment(new Date())
      return (person && currentDate.add(12, 'days') < moment(props.event.start) && currentDate.add(77, 'days') > moment(props.event.start))
    }

    function showSellerCheckout (seller: any) {
      const currentDate = moment(new Date())
      return seller.sellerStatus != null && moment(props.event.start).subtract(13, 'days') > currentDate
    }

    function showSellerDetails (seller: any) {
      const currentDate = moment(new Date())
      const isSeller = seller.sellerStatus === 1
      return isSeller && seller.sellerAmount > 0
    }

    function showFinished (seller: any) {
      const currentDate = moment(new Date())
      return seller.sellerStatus === 1 && currentDate > moment(props.event.start)
    }

    async function sellerCheckIn (person: Person) {
      isLoading.value = true
      const from = new Date()
      const to = new Date('2499-12-31 23:59:59')
      const seller: Seller = {
        persId: person.id || '',
        refId: props.event.id,
        refType: 'event',
        from,
        to
      }
      try {
        await createSellerApi(seller)
        emit('updateSeller')
      } catch (e) {
        return e
      }
      isLoading.value = false
    }

    async function sellerDelete (id: string) {
      try {
        await deleteSellerApi(id)
        emit('updateSeller')
      } catch (e) {
        return e
      }
    }

    return {
      sellersDetails,
      getDateTime,
      getSellerForPerson,
      showSellerCheckIn,
      showSellerCheckout,
      showSellerDetails,
      showFinished,
      sellerCheckIn,
      sellerDelete,
      isLoading
    }
  }
})
