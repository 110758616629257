import { ApiError, ApiResponse, getResponseData, handleApiErrors, jkasseApi, personApi } from '@/services/api/api'
import { authServices } from '@/services/api/auth/api'
import { Booking, Process } from '@/store/modules/booking/interfaces'

/**
 * create or update person
 * @param data
 */
async function createApi (data: any): Promise<Process> {
  // get auth-token
  const config = {
    headers: await authServices()
  }
  return jkasseApi.post('/booking', data, config)
    .then((res) => {
      const data = getResponseData(res)
      if (data && data.data && data.data.process) {
        return Promise.resolve(data.data.process)
      }
      return Promise.resolve(undefined)
    })
    .catch((err) => {
      const apiError: ApiError = handleApiErrors(err)
      return Promise.reject(apiError)
    })
}

/**
 * get sellers for ref
 * @param refId
 * @param refType
 */
async function getForRefApi (refId: string, refType: string): Promise<Booking[]> {
  // get auth-token
  const config = {
    headers: await authServices()
  }
  return jkasseApi.get(`/booking/getForRef/${refId}/${refType}`, config)
    .then((res) => {
      if (res.data && res.data.data && res.data.data.bookings) {
        return Promise.resolve(res.data.data.bookings)
      }
      return Promise.resolve([])
    })
    .catch((err) => {
      const apiError: ApiError = handleApiErrors(err)
      return Promise.reject(apiError)
    })
}

/**
 * get all bookings for seller
 * @param sellerId
 */
async function getAllForSellerApi (sellerId: string): Promise<[]> {
  // get auth-token
  const config = {
    headers: await authServices()
  }
  return jkasseApi.get(`/booking/getAllForSeller/${sellerId}`, config)
    .then((res) => {
      if (res.data && res.data.data && res.data.data.bookings) {
        return Promise.resolve(res.data.data.bookings)
      }
      return Promise.resolve([])
    })
    .catch((err) => {
      const apiError: ApiError = handleApiErrors(err)
      return Promise.reject(apiError)
    })
}

export {
  createApi,
  getForRefApi,
  getAllForSellerApi
}
