import { ApiError, ApiResponse, getResponseData, handleApiErrors, jkasseApi, personApi } from '@/services/api/api'
import { authServices } from '@/services/api/auth/api'
import { Seller } from '@/store/modules/seller/interfaces'

/**
 * create seller
 * @param data
 */
async function createSellerApi (data: any): Promise<Seller> {
  // get auth-token
  const config = {
    headers: await authServices()
  }
  return jkasseApi.post('/seller', data, config)
    .then((res) => {
      const data = getResponseData(res)
      if (data && data.data && data.data.seller) {
        return Promise.resolve(data.data.seller)
      }
      return Promise.resolve(undefined)
    })
    .catch((err) => {
      const apiError: ApiError = handleApiErrors(err)
      return Promise.reject(apiError)
    })
}

/**
 * delete seller
 * @param id
 */
async function deleteSellerApi (id: string): Promise<boolean> {
  // get auth-token
  const config = {
    headers: await authServices()
  }
  return jkasseApi.delete(`/seller/${id}`, config)
    .then((res) => {
      const data = getResponseData(res)
      if (data && data.success) {
        return Promise.resolve(true)
      }
      return Promise.resolve(false)
    })
    .catch((err) => {
      const apiError: ApiError = handleApiErrors(err)
      return Promise.reject(apiError)
    })
}

/**
 * get sellers for ref
 * @param refId
 * @param refType
 */
async function getForRefApi (refId: string, refType: string): Promise<Seller[]> {
  // get auth-token
  const config = {
    headers: await authServices()
  }
  return jkasseApi.get(`/seller/getForRef/${refId}/${refType}`, config)
    .then((res) => {
      const data = getResponseData(res)
      if (data && data.data && data.data.sellers) {
        return Promise.resolve(data.data.sellers)
      }
      return Promise.resolve([])
    })
    .catch((err) => {
      const apiError: ApiError = handleApiErrors(err)
      return Promise.reject(apiError)
    })
}

/**
 * get seller details data for user and ref
 * @param refId
 * @param refType
 */
async function getSellersDetailsByUserIdAndRefIdApi (refId: string, refType: string): Promise<[]> {
  // get auth-token
  const config = {
    headers: await authServices()
  }
  return jkasseApi.get(`/seller/getSellersDetailsByUserIdAndRefId/${refId}/${refType}`, config)
    .then((res) => {
      const data = getResponseData(res)
      if (data && data.data && data.data.sellerDetails) {
        return Promise.resolve(data.data.sellerDetails)
      }
      return Promise.resolve([])
    })
    .catch((err) => {
      const apiError: ApiError = handleApiErrors(err)
      return Promise.reject(apiError)
    })
}

export {
  createSellerApi,
  deleteSellerApi,
  getForRefApi,
  getSellersDetailsByUserIdAndRefIdApi
}
