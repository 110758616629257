<template>
  <div v-for="(person, index) in persons" :key="person.id">
    <div v-if="!getSellerForPerson(person) && isSeller === false" class="row row-eq-height">
      <div class="dropdown-divider mb-2 mt-2" v-if="index > 1"></div>
      <div class="col-10">
        <div class="row row-eq-height">
          <div class="col-2 col-md-1 text-start">
            <span v-if="!persons" data-bs-toggle="tooltip"
                  data-bs-placement="top" title="Bitte erst Adressdaten hinterlegen!">
              <b-icon-x-square-fill class="fs-2 text-danger"/>
            </span>
            <span v-if="persons" data-bs-toggle="tooltip"
                  data-bs-placement="top" title="nicht als Verkäufer angemeldet">
              <b-icon-star-fill class="fs-2 text-light"/>
            </span>
          </div>
          <div class="col-10 col-md-5 text-start">
            <p class="fs-4 mb-0"><strong>{{ person.lastname + ', ' + person.firstname }}</strong></p>
            <p class="mb-0"><small class="text-secondary">Verkäufername</small></p>
          </div>
          <div class="col-6 col-md-3 text-center">
            <p class="fs-4 mb-0 text-danger"><strong>---</strong></p>
            <p class="mb-0"><small class="text-secondary">Verkäufer-Nr.</small></p>
          </div>
          <div class="col-6 col-md-3 text-end">
            <p class="fs-4 mb-0"><strong>
              {{
                new Intl.NumberFormat('de-DE', {
                  style: 'currency',
                  currency: 'EUR'
                }).format(
                  0
                )
              }}
            </strong></p>
            <p class="mb-0"><small class="text-secondary">Verkaufserlös</small></p>
          </div>
        </div>
      </div>
      <div class="col-2 col-md-2 text-end">
        <a href="#" v-if="showSellerCheckIn(person)" class="txt-primary fs-2" data-bs-toggle="tooltip"
                data-bs-placement="top" title="Als Verkäufer anmelden" :disabled="isLoading"
                v-on:click="sellerCheckIn(person)">
          <b-icon-person-plus-fill/>
        </a>
      </div>
    </div>
    <div v-if="getSellerForPerson(person) && isSeller === true" class="row row-eq-height">
      <div class="col-10">
        <div class="row row-eq-height">
          <div class="col-2 col-md-1 text-start">
          <span v-if="!person" data-bs-toggle="tooltip"
                data-bs-placement="top" title="Bitte erst Adressdaten hinterlegen!">
            <b-icon-x-square-fill class="fs-2 text-danger"/>
          </span>
            <span v-if="getSellerForPerson(person).sellerStatus === null && person" data-bs-toggle="tooltip"
                  data-bs-placement="top" title="nicht als Verkäufer angemeldet">
            <b-icon-icon-star-fill class="fs-2 text-light"/>
          </span>
            <span v-if="getSellerForPerson(person).sellerStatus === 0" data-bs-toggle="tooltip"
                  data-bs-placement="top" title="Warten auf Verlosung der Verkaufsnummern">
            <b-icon-hourglass-split class="fs-2 text-warning"/>
          </span>
            <span v-if="getSellerForPerson(person).sellerStatus === 1 && !showFinished(getSellerForPerson(person))" data-bs-toggle="tooltip"
                  data-bs-placement="top" title="Verkäufernummer zugeteilt">
            <b-icon-person-check-fill class="fs-2 text-success"/>
          </span>
            <span v-if="showFinished(getSellerForPerson(person))" data-bs-toggle="tooltip"
                  data-bs-placement="top" title="erfolgreich verkauft">
            <b-icon-check-lg class="fs-2 text-success"/>
          </span>
            <span v-if="getSellerForPerson(person).sellerStatus >= 90" data-bs-toggle="tooltip"
                  data-bs-placement="top" title="Verkäufernummer gesperrt">
            <b-icon-lock-fill class="fs-2 text-danger"/>
          </span>
          </div>
          <div class="col-10 col-md-5 text-start" v-if="getSellerForPerson(person).sellerStatus < 90">
            <p class="fs-4 mb-0"><strong>{{ getSellerForPerson(person).lastname ? getSellerForPerson(person).lastname + ',' : 'nicht angemeldet' }}
              {{ getSellerForPerson(person).firstname }}</strong></p>
            <p class="mb-0"><small class="text-secondary">Verkäufername</small></p>
          </div>
          <div class="col-6 col-md-3 text-center" v-if="getSellerForPerson(person).sellerStatus < 90">
            <p class="fs-4 mb-0 text-danger"><strong>{{ getSellerForPerson(person).sellerNumber ? getSellerForPerson(person).sellerNumber : '---' }}</strong></p>
            <p class="mb-0"><small class="text-secondary">Verkäufer-Nr.</small></p>
          </div>
          <div class="col-10 col-md-10 text-start" v-if="getSellerForPerson(person).sellerStatus === 90">
            <p class="fs-4 mb-0 text-danger"><strong>Für den Verkauf gesperrt!</strong></p>
            <div class="text-danger">
              <p class="fw-bold">
                Grund: Mehrfache Anmeldung zu einer Börse!
              </p>
              <p>
                Du hast Dich mehrfach unter verschiedenen Namen für diese Börse angemeldet.
              </p>
              <p>
                Damit möglichst viele Familien die Chance haben, sich bei unserer Babybörse als Verkäufer zur
                registrierren,
                werden doppelte Anmeldungen / Registrierungen, beispielsweise auf den Mann, die Frau, einen Verwandten
                usw.,
                von uns bei Erkennen entfernt / deaktiviert.
              </p>
            </div>
          </div>
          <div class="col-10 col-md-10 text-start" v-if="getSellerForPerson(person).sellerStatus > 90">
          <p class="fs-4 mb-0 text-danger"><strong>Keine Nummer zugeteilt</strong></p>
            <div>
              <p class="fw-bold">
                Aufgrund der hohen Nachfrage konnten wir leider keine Nummer mehr zuteilen.
              </p>
              <p>
                Bitte melde Dich rechtzeitig zur nächsten Börse an.
              </p>
            </div>
          </div>
          <div class="col-6 col-md-3 text-end" v-if="getSellerForPerson(person).sellerStatus < 90">
            <p class="fs-4 mb-0"><strong>
              {{
                new Intl.NumberFormat('de-DE', {
                  style: 'currency',
                  currency: 'EUR'
                }).format(
                  getSellerForPerson(person).sellerAmount ? getSellerForPerson(person).sellerAmount : 0
                )
              }}
            </strong></p>
            <p class="mb-0"><small class="text-secondary">Verkaufserlös</small></p>
          </div>
        </div>
      </div>
      <div class="col-2 col-md-2 text-end">
        <a href="#" v-show="showSellerCheckout(getSellerForPerson(person))"
                class="text-danger fs-2" data-bs-toggle="modal" :data-bs-target="'#exitSellerModal' + getSellerForPerson(person).sellerId"
                data-bs-placement="top" title="Als Verkäufer abmelden">
          <b-icon-person-dash-fill/>
        </a>
        <router-link v-if="showSellerDetails(getSellerForPerson(person))" class="btn btn-primary"
                     :to="{ name: 'SellerBookings', params: { sellerId: getSellerForPerson(person).sellerId} }"
                     title="Details zu verkauften Artikeln anzeigen">
          <span><b-icon-caret-right-fill/></span>
        </router-link>
      </div>
      <!-- Modal -->
      <div class="modal fade" v-if="showSellerCheckout(getSellerForPerson(person))"
           :id="'exitSellerModal' + getSellerForPerson(person).sellerId" tabindex="-1"
           :aria-labelledby="'exitSellerModalLabel' + getSellerForPerson(person).sellerId"
           aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" :id="'exitSellerModalLabel' + getSellerForPerson(person).sellerId">
                Als Verkäufer abmelden?
              </h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <p>Willst Du Dich als Verkäufer von <strong>"{{ event.name }}"
                am {{ getDateTime(event.start, 'LLL') }}</strong> abmelden?</p>
              <p v-if="!getSellerForPerson(person).sellerNumber">Durch die Abmeldung nimmst Du nicht mehr an der Verlosung der
                Verkäufernummern teil.</p>
              <p class="text-danger" v-if="getSellerForPerson(person).sellerNumber"><strong>ACHTUNG!<br>Deine Verkäufernummer wird hierdurch
                für einem
                anderen Verkäufer freigegeben.</strong></p>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">abbrechen</button>
              <button type="button" class="btn btn-danger" data-bs-dismiss="modal"
                      v-on:click="sellerDelete(getSellerForPerson(person).sellerId)">abmelden
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref } from 'vue'
import moment from 'moment'
import { Seller } from '@/store/modules/seller/interfaces'
import { createSellerApi, deleteSellerApi, getSellersDetailsByUserIdAndRefIdApi } from '@/services/api/seller/api'
import { Event } from '@/store/modules/event/interfaces'
import { Person } from '@/services/api/person/interfaces'

export default defineComponent({
  name: 'EventUserDetailComp',
  emits: ['updateSeller'],
  props: {
    event: {
      type: Object as PropType<Event>,
      required: true
    },
    persons: {
      type: Object as PropType<Person[]>,
      required: true
    },
    isSeller: {
      type: Boolean,
      required: false
    }
  },
  setup (props, { emit }) {
    const isLoading = ref(false)

    function getDateTime (date: Date, format: string) {
      return moment(date).format(format)
    }

    const sellersDetails = ref()

    async function getSellersDetails () {
      const refId = props.event.id
      const refType = 'event'
      try {
        sellersDetails.value = await getSellersDetailsByUserIdAndRefIdApi(refId, refType)
      } catch (e) {
        return e
      }
    }

    getSellersDetails()

    function getSellerForPerson (person: Person): any | void {
      const persId: string = person.id || ''
      return sellersDetails.value ? sellersDetails.value.find((o: any) => o.persId === persId) : undefined
    }

    function showSellerCheckIn (person: Person) {
      const currentDate = moment(new Date())
      return (person && currentDate.add(12, 'days') < moment(props.event.start) && currentDate.add(77, 'days') > moment(props.event.start))
    }

    function showSellerCheckout (seller: any) {
      const currentDate = moment(new Date())
      return seller.sellerStatus != null && moment(props.event.start).subtract(13, 'days') > currentDate
    }

    function showSellerDetails (seller: any) {
      const currentDate = moment(new Date())
      const isSeller = seller.sellerStatus === 1
      return isSeller && seller.sellerAmount > 0
    }

    function showFinished (seller: any) {
      const currentDate = moment(new Date())
      return seller.sellerStatus === 1 && currentDate > moment(props.event.start)
    }

    async function sellerCheckIn (person: Person) {
      isLoading.value = true
      const from = new Date()
      const to = new Date('2499-12-31 23:59:59')
      const seller: Seller = {
        persId: person.id || '',
        refId: props.event.id,
        refType: 'event',
        from,
        to
      }
      try {
        await createSellerApi(seller)
        emit('updateSeller')
      } catch (e) {
        return e
      }
      isLoading.value = false
    }

    async function sellerDelete (id: string) {
      try {
        await deleteSellerApi(id)
        emit('updateSeller')
      } catch (e) {
        return e
      }
    }

    return {
      sellersDetails,
      getDateTime,
      getSellerForPerson,
      showSellerCheckIn,
      showSellerCheckout,
      showSellerDetails,
      showFinished,
      sellerCheckIn,
      sellerDelete,
      isLoading
    }
  }
})
</script>

<style scoped>

</style>
