
import { computed, defineComponent, PropType, ref } from 'vue'
import { Shop } from '@/store/modules/shop/interfaces'
import { useStore } from '@/use/useStore'
import { useRoute } from 'vue-router'
import { AUTH_STORE } from '@/store/constants'
import { getAllForSellerApi } from '@/services/api/booking/api'

import moment from 'moment'
import { Event } from '@/store/modules/event/interfaces'
import { Person } from '@/services/api/person/interfaces'
import { getPersonBySellerIdApi } from '@/services/api/person/api'
import { getEventBySellerIdApi } from '@/services/api/event/api'

export default defineComponent({
  name: 'SellerUserBookings',
  props: {
    shop: {
      type: Object as PropType<Shop>
    }
  },
  setup () {
    const store = useStore()
    const route = useRoute()
    const currentUser = computed(() => store.getters[AUTH_STORE.GETTERS.GET_USER_DATA])
    const isLoading = ref(false)
    const bookings = ref()
    const sumBookings = ref(0)
    const countBookings = ref(0)
    const event = ref<Event>()
    const person = ref<Person>()
    const sellerId: string = route.params.sellerId as string

    isLoading.value = true

    async function getPerson (sellerId: string) {
      try {
        person.value = await getPersonBySellerIdApi(sellerId)
      } catch (e) {
        return e
      }
    }

    getPerson(sellerId)

    async function getBookings (sellerId: string) {
      try {
        bookings.value = await getAllForSellerApi(sellerId)
        if (bookings.value) {
          for (let c = 0; c < bookings.value.length; c++) {
            const booking = bookings.value[c]
            sumBookings.value = sumBookings.value + booking.bs
          }
          countBookings.value = bookings.value.length
        }
      } catch (e) {
        return e
      }
    }

    getBookings(sellerId)

    async function getEvent (sellerId: string) {
      try {
        event.value = await getEventBySellerIdApi(sellerId)
        isLoading.value = false
      } catch (e) {
        isLoading.value = false
        return e
      }
    }

    getEvent(sellerId)

    function getCurrency (amount: number): string {
      const formatter = new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR'
      })

      return formatter.format(amount)
    }

    function getDateTime (date: Date, format: string) {
      return moment(date).format(format)
    }

    return {
      currentUser,
      bookings,
      isLoading,
      sumBookings,
      countBookings,
      event,
      person,
      getCurrency,
      getDateTime
    }
  }
})
