
import { computed, defineComponent, PropType, ref } from 'vue'
import { ApiResponse } from '@/services/api/api'
import '@/validations'
import moment from 'moment'
import EventUserDetailComp from '@/components/shop/event/user/EventUserDetailComp.vue'
import { getAllEventsForUserApi } from '@/services/api/event/api'
import { Event } from '@/store/modules/event/interfaces'
import { AUTH_STORE, SHOP_STORE } from '@/store/constants'
import { store } from '@/store'
import { Person } from '@/services/api/person/interfaces'

export default defineComponent({
  name: 'EventUserListComp',
  components: { EventUserDetailComp },
  emits: ['updateEvents'],
  props: {
    persons: {
      type: Object as PropType<Person[]>,
      required: true
    },
    dateFrom: {
      type: Date,
      required: false
    },
    dateTo: {
      type: Date,
      required: false
    },
    isSeller: {
      type: Boolean,
      required: false
    },
    sort: {
      type: String,
      required: false
    }
  },
  setup (props, { emit }) {
    const shop = computed(() => store.getters[SHOP_STORE.GETTERS.GET_SHOP_DATA])
    let lastEventId = 'old'
    const events = ref<Event[]>()

    function isNewEvent (eventId: string, index: number): boolean {
      const lastId: string = lastEventId
      lastEventId = eventId
      return lastId !== eventId || index === 0
    }

    function getDateTime (date: Date, format: string) {
      return moment(date).format(format)
    }

    async function getEvents () {
      try {
        const apiResponse: ApiResponse = await getAllEventsForUserApi(
          props.dateFrom, props.dateTo, props.isSeller, shop.value.id, props.sort
        )
        if (apiResponse.data && apiResponse.data.events) {
          events.value = apiResponse.data.events
        }
      } catch (e) {
        events.value = []
      }
    }
    getEvents()

    function update () {
      emit('updateEvents')
    }

    return {
      events,
      isNewEvent,
      getDateTime,
      update
    }
  }
})
