<template>
  <div class="seller-event-data container-lg">
    <h1>Verkaufsdaten von {{ currentUser.username }}</h1>
    <div v-if="!persons">
      <div class="alert alert-danger" role="alert">
        <h2><b-icon-exclamation-triangle-fill /> Fehlende Adressdaten <b-icon-exclamation-triangle-fill /></h2>
        <p>Für Deinen Benutzeraccount sind noch keine Adressdaten angelegt.</p>
        <p>Du kannst Dich als Verkäufer nur für einen Termin registrieren, wenn Du Deine Adressdaten hinterlegt hast.</p>
        <p>Gehe zu Deinem Profile und hinterlege Deine Adressdaten:</p>
        <router-link class="btn btn-primary" :to="{ name: 'UserProfile' }">
          Adressdaten jetzt hinterlegen
        </router-link>
      </div>
    </div>
    <div v-else>
      <div class="card mt-3">
        <div class="card-header"><h3>Neue verfügbare Termine</h3></div>
        <div class="card-body pt-0">
          <EventUserListComp  @update-events="reload" :is-seller="false" :persons="persons" :seller-status="0" :date-from="getDateTimeNewEvents()"/>
        </div>
      </div>
      <div class="card mt-3">
        <div class="card-header"><h3>Als Verkäufer angemeldet</h3></div>
        <div class="card-body pt-0">
          <EventUserListComp  @update-events="reload" :is-seller="true" :persons="persons" :seller-status="1"/>
        </div>
      </div>
      <div class="card mt-3">
        <div class="card-header"><h3>Erfolgreich verkauft</h3></div>
        <div class="card-body pt-0">
          <EventUserListComp
            @update-events="reload" :date-from="new Date(1900, 1)" :date-to="new Date()"
            :is-seller="true" :persons="persons" :seller-status="1" sort="DESC"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref } from 'vue'
import { Shop } from '@/store/modules/shop/interfaces'
import { useStore } from '@/use/useStore'
import { useRouter } from 'vue-router'
import { AUTH_STORE } from '@/store/constants'
import EventUserListComp from '@/components/shop/event/user/EventUserListComp.vue'
import { getAllPersonsForUserApi, getPersonForUser } from '@/services/api/person/api'
import { getAddressByRefRole } from '@/services/api/address/api'
import { Person } from '@/services/api/person/interfaces'
import moment from 'moment'

export default defineComponent({
  name: 'SellerUserEvents',
  components: { EventUserListComp },
  props: {
    shop: {
      type: Object as PropType<Shop>
    }
  },
  setup () {
    const store = useStore()
    const router = useRouter()
    const currentUser = computed(() => store.getters[AUTH_STORE.GETTERS.GET_USER_DATA])
    const isLoading = ref(false)
    const person = ref()
    const persons = ref<Person[]>()
    const address = ref()

    async function getPerson () {
      try {
        person.value = await getPersonForUser()
        if (person.value) {
          address.value = await getAddressByRefRole(person.value.id, 'person', 'main')
        }
      } catch (e) {
        return e
      }
    }

    getPerson()

    async function getAllPersons () {
      try {
        persons.value = await getAllPersonsForUserApi()
        isLoading.value = false
      } catch (e) {
        isLoading.value = false
        return e
      }
    }

    getAllPersons()

    async function reload () {
      router.go(0)
    }

    function getDateTimeNewEvents () {
      return moment(new Date().setUTCHours(23, 59, 59, 9999)).add(12, 'd').toDate()
    }

    return {
      currentUser,
      person,
      persons,
      address,
      isLoading,
      getPerson,
      reload,
      getDateTimeNewEvents
    }
  }
})
</script>

<style scoped>

</style>
